<template>
  <div class="login-card">
    <div class="row">
      <div class="col-md-6 d-md-block d-none">
        <img class="w-100" src="../../assets/img/auth/login-backgroun.png" alt="">
        <div class="etmaam">
          <img src="../../assets/img/global/Logo.webp" alt="Etmaam Logo">
          <p>
            {{
              this.$i18n.locale == 'ar' ?
                `استفد من خبرتنا واستمتع بخدمات متميزة تلبي احتياجاتك بدقة واحترافية.`
                :
                `Benefit from our experience and enjoy distinguished services that meet your needs accurately and
            professionally.`

            }}
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <Form class="auth-form" @submit="HandelForgot">
          <div class="row">
            <div class="col-12">
              <h1 class="title">{{ $t('Forgot') }}</h1>
            </div>
            <div class="col-12 field">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.77042 3.87129C1.48293 3.70649 1.11628 3.80596 0.951487 4.09345C0.786694 4.38094 0.88616 4.74759 1.17365 4.91238L1.77042 3.87129ZM7.36455 7.7695L7.666 7.25071L7.66294 7.24896L7.36455 7.7695ZM10.6155 7.7695L10.3177 7.24859L10.314 7.25072L10.6155 7.7695ZM16.8221 4.91273C17.1098 4.74829 17.2097 4.38176 17.0452 4.09407C16.8808 3.80639 16.5143 3.70648 16.2266 3.87093L16.8221 4.91273ZM4.70495 1.64688H13.2922V0.446875H4.70495V1.64688ZM13.2922 1.64688C14.7676 1.64688 15.9636 2.84288 15.9636 4.31823H17.1636C17.1636 2.18014 15.4303 0.446875 13.2922 0.446875V1.64688ZM15.9636 4.31823V11.6788H17.1636V4.31823H15.9636ZM15.9636 11.6788C15.9636 13.1541 14.7676 14.3501 13.2922 14.3501V15.5501C15.4303 15.5501 17.1636 13.8169 17.1636 11.6788H15.9636ZM13.2922 14.3501H4.70495V15.5501H13.2922V14.3501ZM4.70495 14.3501C3.2296 14.3501 2.03359 13.1541 2.03359 11.6788H0.833594C0.833594 13.8169 2.56686 15.5501 4.70495 15.5501V14.3501ZM2.03359 11.6788V4.31823H0.833594V11.6788H2.03359ZM2.03359 4.31823C2.03359 2.84288 3.2296 1.64688 4.70495 1.64688V0.446875C2.56686 0.446875 0.833594 2.18014 0.833594 4.31823H2.03359ZM1.17365 4.91238L7.06617 8.29005L7.66294 7.24896L1.77042 3.87129L1.17365 4.91238ZM7.06311 8.28828C7.64835 8.62834 8.31315 8.80746 8.99001 8.80746V7.60746C8.52492 7.60746 8.06812 7.48438 7.666 7.25072L7.06311 8.28828ZM8.99001 8.80746C9.66686 8.80746 10.3317 8.62834 10.9169 8.28828L10.314 7.25072C9.91189 7.48438 9.45509 7.60746 8.99001 7.60746V8.80746ZM10.9132 8.29041L16.8221 4.91273L16.2266 3.87093L10.3177 7.2486L10.9132 8.29041Z"
                  fill="#0a3041" />
              </svg>
              <Field name="email" type="email" :placeholder="$t('Email')" />
              <ErrorMessage name="email" class="error-message" />
            </div>
            <div class="col-12">
              <button class="btn-main">{{ $t("Confirm") }}</button>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-between ">
              <router-link to="/login" class="btn-third">
                {{ $t('Login') }}
              </router-link>
            </div>
          </div>
        </Form>
        <Verification v-if="ShowVerification" :email="emailProp" />
      </div>
    </div>
  </div>
</template>
<script>
import Verification from './Verification_Reset.vue';
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
// import vee-validate 
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  data() {
    return {
      ShowVerification: false,
      emailProp:''
    }
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الالكتروني مطلوب' : 'email is required').email(cookie.get('lang') == 'ar' ? 'يجب ان يكون البريد الالكتروني محقق' : 'email must be valid'),
    });

    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Verification
  },
  methods: {
    async HandelForgot(values) {
      
      const formData = {
        "email": values.email,
      }
      await axios.post(`/user/auth/request-reset-password-code`, formData)
        .then((res) => {
          if (res.status == 200) {
            this.emailProp = values.email;
            this.ShowVerification = true;
          }
        })
        .catch(function (error) {
          if (error) {
            notify({
              type: "error",
              title: "خـطـأ !!",
              text: error.response?.data?.message,
            });
          }
        });
    }
  },
  mounted() {


  }
}
</script>